/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useTheme } from '@emotion/react';
import Image from 'next/image';

import Link from 'next/link';
import { useIsEnterpriseUser } from 'src/js/endpoints/users';
import SVGIcon from 'src/js/components/SvgIcon';
import healthlineLogo from 'public/img/Healthline_logo.svg';
import { isTherapist } from 'utils/doctor';
import {
  CloseButton,
  HamburgerButton,
  Button,
} from 'src/js/nextgen/plushcare-components';
import { logout } from 'src/js/actions/UserActions';
import {
  appUrl, todayInTimezone, updateLocation,
} from 'src/js/utils';

import { getStoredUser, preBookedAppointment } from 'src/js/store/selectors';
import { releaseAppointmentFromPreBooking } from 'src/js/components/bookingFlow/PreBookedAppointmentWatcher';
import { BOOKING_FLOW, HOLD_APT_TIME_COOKIE_NAME, LEAVING_BOOKING_FLOW_ALERT } from 'src/js/nextgen/utils/constants';
import { preBookedAptCRUD, SELECTED_APT_KIND_OPTIONS, selectedTherapyKindCRUD } from 'src/js/actions/BookingActions';
import CookieStorage from 'src/js/utils/CookieStorage';
import { calcBookingHoldLeftTime } from 'src/js/components/Stepper/Timer/Timer';
import PlushCareLogoSection from 'src/js/nextgen/components/navigation/PlushCareLogoSection';
import ImpersonatorSection from 'src/js/nextgen/components/navigation/ImpersonatorSection';
import { flushBookingFlow } from 'src/js/components/bookingFlow/pcBookingFlowHook';
import { PhoneNumber } from 'src/js/nextgen/components/PhoneNumber/PhoneNumber';
import {
  BannerContainer,
  DesktopNavigation,
  HealthlineBanner,
  MobileMenuStyled,
  MobileNavigation,
  NavStyled,
} from 'src/js/nextgen/components/navigation/Navigation.styled';
import AccoladeCareLogo from 'public/img/nextgen/AccoladeCare.svg';
import { useBrand } from 'src/js/nextgen/providers/BrandProvider';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import { useFeatureFlag } from 'src/js/abTesting/hooks';
import { useIsEmbed } from 'src/js/hooks/useIsEmbed';

const ENV = process.env.NEXT_PUBLIC_ENV;

const Navigation = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [{ enabled: isMessagingWebEnabled }] = useFeatureFlag('messaging-web-enabled', { autoUpdate: true });
  const { isEmbed } = useIsEmbed();
  const theme = useTheme();
  const brand = useBrand();
  const iconColor = theme.colors.foreground;
  const [isMessagingEnabled, setIsMessagingEnabled] = useState(false);
  const isNavMessagingEnabled = brand.color_scheme !== AppVariants.Bsc;
  const accoladeCareStyleFixes = brand.application_default === AppVariants.AccoladeCare
    ? { style: { marginTop: 6 } }
    : {};

  const {
    firstName,
    userprofile,
    impersonator,
    isLoggedIn,
  } = useSelector(getStoredUser);

  const displayName = userprofile?.preferred_name || firstName;
  // These routed should always use the enterprise navigation even if the user is unauthenticated
  // Only use for components that are specific to enterprise and will be accessed without auth
  const unauthedEnterpriseRoutes = [
    appUrl.enterprise.standaloneVerify,
    appUrl.enterprise.verify,
    appUrl.registration.enterprise,
    appUrl.registration.enterpriseSuccess,
    appUrl.authentication.ssoEntry,
  ];
  const displayAsEnterprise = useIsEnterpriseUser() || unauthedEnterpriseRoutes.some(x => router.asPath.includes(x));

  useEffect(() => {
    flushBookingFlow(dispatch, router);
  }, [dispatch, router]);

  const marketingSiteUrl = {
    dev: 'https://marketing.int.dev.plushcare.com/',
    stage: 'https://marketing.stage.plushcare.com/',
    prod: 'https://plushcare.com/',
  }[process.env.NEXT_PUBLIC_ENV];

  const { variant } = useAppVariant();
  const isAccolade = variant === AppVariants.AccoladeCare;
  const logoUrl = isAccolade ? 'https://www.accoladecare.com/' : marketingSiteUrl;

  useEffect(() => {
    if (isLoggedIn) {
      setIsMessagingEnabled(isMessagingWebEnabled);
    }
  }, [isLoggedIn, isMessagingWebEnabled]);

  const logoutAction = () => {
    dispatch(logout());
  };

  const preBookedAptmntFromGlobalStore = useSelector(preBookedAppointment);
  const preBookedAptmnt = preBookedAptCRUD.load() || preBookedAptmntFromGlobalStore;
  const heldAptTimeStart = CookieStorage.get(HOLD_APT_TIME_COOKIE_NAME);
  const showWarnBanner = preBookedAptmnt && heldAptTimeStart
    ? calcBookingHoldLeftTime(preBookedAptmnt.hold_length, heldAptTimeStart, todayInTimezone()) > 0
    : false;
  const selectedAptKind = selectedTherapyKindCRUD.load();

  // eslint-disable-next-line no-nested-ternary
  const maybeBookingFlowType = isInTherapyBookingFlow() ? BOOKING_FLOW.THERAPY : BOOKING_FLOW.PC;
  const [signInLink, setSignInLink] = useState(appUrl.login);
  const actualLogoUrl = logoUrl;
  // Logged out links are hosted on wordpress - need to be handled differently
  const domain = (ENV === 'prod') ? 'https://plushcare.com' : '';
  const loggedOutLinks = [
    !isAccolade ? { text: 'How it Works', link: `${domain}/how-it-works/`, className: 'nav-how-it-works-link' } : null,
    !isAccolade ? { text: 'What we Treat', link: `${domain}/what-we-treat/`, className: 'nav-what-we-treat-link' } : null,
    !isAccolade ? { text: 'Membership', link: `${domain}/membership/`, className: 'nav-membership-link' } : null,
    { text: 'Help', link: !isAccolade ? 'https://support.plushcare.com/' : 'https://accoladecare.zendesk.com', className: 'nav-help-link' },
    { text: 'Sign In', link: signInLink, className: 'nav-sign-in-link' },
  ].filter(Boolean);
  const loggedInLinks = [
    { text: 'My Dashboard', link: appUrl.dashboard, className: 'nav-dashboard-link' },
    isMessagingEnabled ? { text: 'Messages', link: appUrl.messaging, className: 'nav-messaging-link' } : null,
    { text: 'My Profile', link: appUrl.profile.user, className: 'nav-profile-link' },
    {
      text: 'Sign Out', link: '', onClick: logoutAction, className: 'nav-sign-out-link',
    },
  ].filter(Boolean);
  const [displayMobileMenu, toggleMobileMenu] = useState(false);
  const [displayDropDownMenu, toggleDropDownMenu] = useState(false);
  const [displayHealthline, toggleHealthline] = useState(false);

  const nameText = (isLoggedIn) ? `Hi ${displayName}` : 'Sign In';
  const routes = (isLoggedIn) ? loggedInLinks : loggedOutLinks;
  const buttonTag = (isLoggedIn) ? 'button' : 'a';
  const buttonType = (isLoggedIn) ? 'navigation-small-signed-in' : 'navigation-small-signed-out';
  // below should be done differently, its a hack solution
  const onBookingPage = router.asPath.includes('/booking/');

  useEffect(() => {
    if (router.query.healthline) {
      toggleHealthline(true);
    }
  }, [router.query]);

  const bookingFlowWarner = async (e, logoLink) => {
    e.preventDefault();
    const onSuccessPage = router.asPath.includes('/booking-success/');
    if (!preBookedAptmnt || !showWarnBanner || onSuccessPage) updateLocation(logoLink);
    // eslint-disable-next-line no-alert
    else if (window.confirm(LEAVING_BOOKING_FLOW_ALERT)) {
      await releaseAppointmentFromPreBooking({ appointment_id: preBookedAptmnt.id }, dispatch);
      updateLocation(logoLink);
    }
  };

  const onLogoClick = (e, logoLink) => {
    bookingFlowWarner(e, logoLink);
    return false;
  };

  // only desktop
  const onPlushcareButtonClick = (event) => {
    const isOnRegistrationPage = router.asPath.includes(appUrl.booking.register.pc);
    event.preventDefault();
    if (isLoggedIn) {
      toggleDropDownMenu(!displayDropDownMenu);
    } else if (isInTherapyBookingFlow() || isOnRegistrationPage) {
      updateLocation(signInLink);
    } else {
      bookingFlowWarner(event, signInLink);
    }
  };

  const routeList = number => routes.map(({
    link, text, className, onClick,
  }) => (
    <li key={`${link}-${number}`}>
      { link === appUrl.messaging ? (
        // We need to use <a> for external links
        <a href={link} className={className} onClick={(onClick) ? () => onClick() : null}>
          {text}
        </a>
      ) : (
        <Link href={link} passHref>
          <a href={link} className={className} onClick={(onClick) ? () => onClick() : null}>
            {text}
          </a>
        </Link>
      )}
    </li>
  ));

  useEffect(() => {
    const url = isLoggedIn ? appUrl.dashboard : defineNextAfterLoginPage({
      path: router.asPath,
      withTherapySub: false,
      withSelectRecurringOpts: preBookedAptmnt?.pricing?.filter(plan => plan.available).length > 1 || false,
      isUserLoggedIn: false,
      withConfirmProfilePage: false,
      bookingFlowType: maybeBookingFlowType,
      isReschedule: false,
      isRecurringAppointment: false,
      withSelectAptKindOpts: true,
      selectedAptKind: selectedAptKind || SELECTED_APT_KIND_OPTIONS.paidApt,
    });
    setSignInLink(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, isLoggedIn, preBookedAptmnt]);

  return (
    <>
      <ImpersonatorSection impersonator={impersonator} />
      {onBookingPage && displayHealthline && (
        <BannerContainer>
          <HealthlineBanner className="healthline-banner">
            <p>
              Welcome from&nbsp;
              <img src={healthlineLogo} alt="Healthline" />
            </p>
          </HealthlineBanner>
        </BannerContainer>
      )}
      {!isEmbed && (
        <NavStyled role="navigation">
          <PlushCareLogoSection
            displayAltLogo={displayMobileMenu}
            logoUrl={actualLogoUrl}
            onLogoClick={onLogoClick}
            enterprise={displayAsEnterprise}
          />
          <DesktopNavigation>
            {brand.color_scheme === AppVariants.Bsc && (
              <li className="nav-item">
                <Image
                  src={AccoladeCareLogo}
                  alt={brand.application_title}
                  width={160}
                  height={30}
                />
              </li>
            )}
            {isMessagingEnabled && isNavMessagingEnabled && (
              <li className="nav-item">
                <a href={appUrl.messaging}>
                  Message care team
                  <SVGIcon
                    name="messageBubble"
                    svgDimensions="29"
                  />
                </a>
              </li>
            )}
            <li className="nav-item" {...accoladeCareStyleFixes}>
              <PhoneNumber />
            </li>
            <li>
              <Button
                className="plushcare-button"
                tag={buttonTag}
                link={signInLink}
                type={buttonType}
                noLoader
                onClick={onPlushcareButtonClick}
                size="small"
                data-private
              >
                {nameText}
                {isLoggedIn
                  && (
                    <SVGIcon
                      name="chevron"
                      svgDimensions="10"
                      fill={iconColor}
                    />
                  )}
              </Button>
              {displayDropDownMenu
                && (
                  <ul className="plushcare-dropdown-menu">
                    {routeList(1)}
                  </ul>
                )}
            </li>
          </DesktopNavigation>

          <MobileNavigation>
            {brand.color_scheme === AppVariants.Bsc && (
              <Image
                src={AccoladeCareLogo}
                alt={brand.application_title}
                width={190}
                height={30}
              />
            )}
            {!displayMobileMenu
              && <HamburgerButton onClick={() => toggleMobileMenu(!displayMobileMenu)} />}
            {displayMobileMenu
              && <CloseButton onClick={() => toggleMobileMenu(!displayMobileMenu)} />}
          </MobileNavigation>

          {displayMobileMenu
            && (
              <MobileMenuStyled>
                <ul>
                  {routeList(2)}
                </ul>
              </MobileMenuStyled>
            )}
        </NavStyled>
      )}
    </>
  );
};

export default Navigation;

export function defineNextAfterLoginPage(params) {
  const isOnRegistrationPage = params.path.includes('/register/');
  const isInTherapyFlow = isInTherapyBookingFlow();

  let url = appUrl.login;
  // after login user is redirected to page from which he desired to authenticate
  // but for registration page logic is different - we are skipping it and navigating to next page after registration
  if (isInTherapyFlow) url = isOnRegistrationPage ? `${appUrl.login}?next=${appUrl.booking.therapy.payment}` : `${appUrl.login}?next=${params.path}`;
  // for PC & RX booking flows logic remains as-is
  if (!isInTherapyFlow && isOnRegistrationPage) url = `${appUrl.login}?next=${appUrl.booking.payment.pc}`;

  return url;
}

function isInTherapyBookingFlow() {
  const apt = preBookedAptCRUD.load();
  return apt && isTherapist(apt.doctor_type || apt.doctor.doctor.doctor_type);
}
