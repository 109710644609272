import { PlushcareWebAPI, apiUrl } from 'src/js/utils';
import {
  post, get, success, V2Response, isError, error as v2Error,
} from 'src/js/utils/apiV2';
import useSWR from 'swr';
import { useUserData } from 'src/js/endpoints/users';

interface LookupResponse {
  coinsurance_amount?: string,
  coinsurance_percent?: string,
  copay_amount: string,
  coverage_id: number,
  coverage_type: CoverageType,
  credits?: string,
  deductible_amount?: string,
  deductible_waived: boolean,
  in_network: boolean,
  price: string,
  reason: string,
  same_day_discount: boolean,
  user_insurance: boolean,
  name: string,
  lock_credentials: boolean,
  profile_override: any,
  member_id: string,
  explanation: string,
}

interface LookupPostData {
  first_name: string,
  last_name: string,
  member_id?: string,
  date_of_birth: string,
  payer_id: string,
  payer_name: string,
  doctor_id: number
}

export interface PayerDetailsResponse {
  name: string,
  display_name: string,
  payer_id: string,
  smart_verify: boolean,
}

export const postCostEstimate = (postData: LookupPostData): Promise<V2Response<LookupResponse>> => PlushcareWebAPI.apiPost<LookupPostData>('/v2/insurance/cost-estimate/', postData, true);

export const getPayerDetails = (payerName: PayerDetailsResponse) => get<Array<PayerDetailsResponse>, any>(`/v2/insurance/payer-names/?name=${payerName}`);

export const searchPayerNames = (payerName: string) => get<Array<PayerDetailsResponse>, any>(`/v2/insurance/payer-names/?name=${payerName}`);

// TODO:: this should come off same endpoint as insurance/payer-names on BE
export type PartialPayer = Pick<PayerDetailsResponse, 'display_name' | 'name' | 'payer_id'>;
export const getTopPayers = async (): Promise<V2Response<PartialPayer[]>> => {
  const { data, error } = await PlushcareWebAPI.staticServerGet({
    // baseURLOverride: 'https://api.plushcare.com/', - Uncomment this line when medicare is flag is removed
    url: apiUrl.getTopPayers,
  });

  if (error) {
    return v2Error({
      code: 'CUSTOM',
      code_alt: 'CS',
      message: error.message || '[Error]: Failed fetching top payers',
      error_data: error.data || null,
    });
  }

  // mapping to wanted object from pairs
  return success(data.map(([payer_id, name]: [string, string]) => ({ payer_id, name, display_name: name })));
};

export type CostEstimateResponse = {
  applied_bonus: string | null,
  price: string
}

export const getDefaultUninsuredPrice = (): Promise<CostEstimateResponse> => (
  PlushcareWebAPI.post(apiUrl.pricing.primaryCare.getDefaultUninsuredPrice, {}, false, true).then(
    ({ data }) => {
      return data;
    },
  ));

export type CoverageType = 'enterprise' | 'eligible' | 'tpa' | 'apero';

type Coverage = {
  first_name: string,
  last_name: string,
  date: string,
  patient_first_name: string,
  patient_last_name: string,
  patient_dob: string,
}
type NotInsuredCoverage = Coverage & {
  is_insured: false,
}
// self coverage; coverage type isn't eligible; payer_id not set
type InsuredCoverage = Coverage & {
  price: string,
  coverage_type: CoverageType,
  payer_name: string,
  member_id: string,
  is_insured: true,
  patient_is_dependant: boolean
}
// dependent coverage; coverage type isn't eligible; payer_id not set
type InsuredDependentCoverage = InsuredCoverage & {
  patient_is_dependant: true,
  primary_first_name: string,
  primary_last_name: string,
  primary_dob: string
}
// self coverage; coverage type is eligible; payer_id set
type InsuredEligibleCoverage = InsuredCoverage & {
  payer_id: string,
  member_id: string,
  patient_is_dependant: boolean,
  primary_first_name: string,
  primary_last_name: string,
  primary_dob: string,
}
// dependent coverage; coverage type is eligible; payer_id set
type InsuredDependentEligibleCoverage = InsuredDependentCoverage & {
  payer_id: string,
  patient_is_dependant: true
}
type CoverageResponse = NotInsuredCoverage | InsuredCoverage | InsuredDependentCoverage | InsuredEligibleCoverage
  | InsuredDependentEligibleCoverage

export const getCoverage = async (): Promise<CoverageResponse> => {
  const resp = await get<CoverageResponse>(apiUrl.patients.getCoverage);

  if (isError(resp)) {
    // why we do it?
    // because there is general issue between using useSWR with v2 helpers
    // we need to make version of v2 helpers which will just be compatible with useSWR
    throw resp;
  }
  return resp.payload as CoverageResponse;
};

export const removeInsurance = () => post('/v2/insurance/remove/', {});

export const useCoverage = () => {
  const { data: userData } = useUserData();

  // Prevent fetching coverage if user is not logged in
  const cacheKey = userData.is_logged_in ? 'profile_coverage' : null;

  const {
    data,
    error,
    isValidating,
    mutate,
  } = useSWR(cacheKey, getCoverage);

  return {
    data,
    error,
    isValidating,
    isLoading: isValidating && !data,
    mutate,
  };
};

export const useIsTPAUser = () => {
  const { data: coverage } = useCoverage();
  return (coverage?.is_insured && coverage.coverage_type === 'tpa') || false;
};
