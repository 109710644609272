import { deAnonymizePayloadDoctorType } from 'src/js/endpoints/appointments';
import { DOCTOR_TYPE, DoctorType } from 'src/js/nextgen/utils/constants';
import { addToDate, isDateBefore } from 'src/js/utils/datetime';

export const isDoctorType = (doctorType: string | null | undefined | number, wantedDoctorType: DoctorType) => {
  const isNumeric = (value: string): boolean => !Number.isNaN(Number(value));
  const isNumericCondition = typeof doctorType === 'string' && isNumeric(doctorType);
  if (typeof doctorType === 'number' || isNumericCondition) {
    return deAnonymizePayloadDoctorType({ doctor_type: doctorType })?.doctor_type.toLowerCase() === wantedDoctorType;
  }
  return doctorType ? doctorType.toLowerCase() === wantedDoctorType : false;
};

// eslint-disable-next-line max-len
export const isTherapist = (doctorType: string | null | undefined | number) => isDoctorType(doctorType, DOCTOR_TYPE.THERAPIST);

// eslint-disable-next-line max-len
export const isPediatric = (doctorType: string | null | undefined | number) => isDoctorType(doctorType, DOCTOR_TYPE.PEDIATRICS);

export const doctorTitle = ({
  doctor_type, first_name, last_name, prefix, designation,
}:
  {
    doctor_type: string,
    first_name: string,
    last_name: string,
    prefix?: string,
    designation?: string,
  }) => {
  let doc_prefix = prefix ? `${prefix}` : '';
  doc_prefix = doc_prefix || 'Dr.';
  doc_prefix = !isTherapist(doctor_type) ? `${doc_prefix}` : '';
  const doc_designation = designation ? `, ${designation}` : '';
  return doc_prefix
    ? `${doc_prefix} ${first_name} ${last_name}${doc_designation}`
    : `${first_name} ${last_name}${doc_designation}`;
};

const dateIsInDaysRange = (range: number) => (date: string) => isDateBefore(date, addToDate('day', range));

export const isTherapySupported = (availableDates: string[]) => {
  const acceptableDaysRange = 30;
  return availableDates.some(dateIsInDaysRange(acceptableDaysRange));
};
